// src/state/domainSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchDomainAvailability = createAsyncThunk(
  'domain/fetchDomainAvailability',
  async (domainName, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://cwscp.hostin.pk/api/v1/domain/isdomain-available?domain=${domainName}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const domainSlice = createSlice({
  name: 'domain',
  initialState: {
    domainName: '', // Only stores the domain name input
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    setDomainName: (state, action) => {
      state.domainName = action.payload; // Set domain name without making API call
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDomainAvailability.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDomainAvailability.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDomainAvailability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setDomainName } = domainSlice.actions;
export default domainSlice.reducer;
