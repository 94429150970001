// src/components/Header.js
import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { FaMoon } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

function Header({ toggleDarkMode,open,setOpen }) {
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-dark App-header">
        <div className="container-fluid">
          <div className="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2">
         {!open && <IconButton onClick={() => setOpen(!open)} style={{ color: '#fff' }}>
              <MenuIcon />
            </IconButton>}
          </div>
          <div className="mx-auto order-0">
            <img src="/logo.png" alt="logo" style={{ maxHeight:'70px'}} />
          </div>
          <div className="navbar-collapse collapse w-100 order-3 dual-collapse2 justify-content-end">
           
            <img src="/icon1.png" alt="icon" className="me-2" style={{ maxHeight:'30px' }} />
            <img src="/icon2.png" alt="icon" className="me-2" style={{ maxHeight:'30px' }} />
            <div className="theme-switcher" onClick={toggleDarkMode} style={{ cursor: 'pointer' }}>
              <FaMoon />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
