import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { autoLogin, Autologin, updateUser } from '../state/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
const PaymentConfirmation = () => {
  const [confirmationStatus, setConfirmationStatus] = useState(null);
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');

  const dispatch  = useDispatch()
  const navigate = useNavigate()
  const { domainName } = useSelector((state) => state.domain);
  useEffect(() => {

    // Get query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    const domainParam = queryParams.get('domain');


    console.log(emailParam,"emailParam")
    setEmail(emailParam);

    setDomain(domainParam)
  }, [location.search]);
  const createPayment =async (token,userId) => {
    const req ={
      domain:domain,
      user:userId,
      status:'Complete'
    }
    const response = axios.post('https://cwscp.hostin.pk/api/v1/payment/create-payment',req,{
      headers:{
        'Authorization':`Bearer ${token}`
      }
    });
  }

  useEffect(() => {
    handleLogin(email)
  },[email])
  const handleLogin = async (email) => {
    try {
      const result = await dispatch(autoLogin({ email: email }));
      const updatedData = {
        paymentStatus:true
      }
      await dispatch(updateUser({ id: result.payload.data._id, updatedData }))
      .unwrap()
      .then((response) => {
        console.log(response,"responseresponse")
        alert('User updated successfully!');
      })
      .catch((err) => {
        console.error('Update failed:', err);
      });
      if (autoLogin.fulfilled.match(result)) {
        await createPayment(result.payload.data.token,result.payload.data._id)
        navigate('/user-dashboard');
      }
    } catch (err) {
      console.error(err);
    }
  };
 
  return (
    <div>
      {confirmationStatus ? (
        <h2>{confirmationStatus}</h2>
      ) : (
        <h2>Confirming your payment, please wait...</h2>
      )}
    </div>
  );
};

export default PaymentConfirmation;
