import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const Payment = () => {
  const [transactionReference, setTransactionReference] = useState('');
  const [transactionAmount, setTransactionAmount] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [requestHash, setRequestHash] = useState('');

  const handleSubmitHandshake = async (e) => {
    e.preventDefault();

    const handshakeData = {
      HS_MerchantId: '16506',
      HS_StoreId: '022010',
      HS_MerchantHash: 'OUU362MB1urpXtnMpU0/if9W2C4ZuWwbCphTWcLREpfzRqIzVAeBAQ==',
      HS_MerchantUsername: 'hajiru',
      HS_MerchantPassword: 'KL5v6xUT23JvFzk4yqF7CA==',
      HS_IsRedirectionRequest: '1',
      HS_ReturnURL: 'https://fend.hostin.pk/payment',
      HS_RequestHash: '',
      HS_ChannelId: '1001',
      HS_TransactionReferenceNumber: transactionReference,
    };

    // Generate Request Hash for Handshake
    const handshakeHash = generateHash(handshakeData, 'T8hRYxe8XrKbtjBn', '3967957695762366');
    handshakeData.HS_RequestHash = handshakeHash;

    try {
      const response = await axios.post('https://sandbox.bankalfalah.com/HS/HS/HS', handshakeData);
      if (response.data && response.data.success === 'true') {
        setAuthToken(response.data.AuthToken);
        alert('Success: Handshake Successful');
      } else {
        alert('Error: Handshake Unsuccessful');
      }
    } catch (error) {
      console.error('Handshake Error:', error);
      alert('Error: An error occurred during Handshake');
    }
  };

  const handleSubmitRedirection = async (e) => {
    e.preventDefault();

    const redirectionData = {
      AuthToken: authToken,
      RequestHash: '',
      ChannelId: '1001',
      Currency: 'PKR',
      IsBIN: '0',
      ReturnURL: 'https://fend.hostin.pk/payment',
      MerchantId: '16506',
      StoreId: '022010',
      MerchantHash: 'OUU362MB1urpXtnMpU0/if9W2C4ZuWwbCphTWcLREpfzRqIzVAeBAQ==',
      MerchantUsername: 'hajiru',
      MerchantPassword: 'KL5v6xUT23JvFzk4yqF7CA==',
      TransactionTypeId: '3', // Assuming '3' is selected for Credit/Debit Card
      TransactionReferenceNumber: transactionReference,
      TransactionAmount: transactionAmount,
    };

    // Generate Request Hash for Page Redirection
    const redirectionHash = generateHash(redirectionData, 'T8hRYxe8XrKbtjBn', '3967957695762366');
    redirectionData.RequestHash = redirectionHash;

    try {
      // Submit redirection form to the SSO endpoint
      const response = await axios.post('https://sandbox.bankalfalah.com/SSO/SSO/SSO', redirectionData);
      // Handle response as needed
      alert('Success: Redirected for payment');
    } catch (error) {
      console.error('Redirection Error:', error);
      alert('Error: An error occurred during Page Redirection');
    }
  };

  // Function to generate hash using AES encryption
  const generateHash = (data, key, iv) => {
    let mapString = '';
    Object.keys(data).forEach((key) => {
      if (data[key] !== '') {
        mapString += key + '=' + data[key] + '&';
      }
    });
    mapString = mapString.slice(0, -1); // Remove last '&'

    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(mapString),
      CryptoJS.enc.Utf8.parse(key),
      {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  };

  return (
    <div>
      <h3>Handshake</h3>
      <form onSubmit={handleSubmitHandshake}>
        <input
          type="text"
          placeholder="Order ID"
          value={transactionReference}
          onChange={(e) => setTransactionReference(e.target.value)}
        />
        <button type="submit">Handshake</button>
      </form>

      <h3>Page Redirection Request</h3>
      <form onSubmit={handleSubmitRedirection}>
        <input
          type="text"
          placeholder="Transaction Amount"
          value={transactionAmount}
          onChange={(e) => setTransactionAmount(e.target.value)}
        />
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default Payment;
