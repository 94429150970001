import React from 'react';

function CallBack() {
  return (
    <div>
      {/* <h2>Contact Page</h2>
      <p>This is the Contact Page.</p> */}
    </div>
  );
}

export default CallBack;
