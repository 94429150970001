import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import "bootstrap/dist/css/bootstrap.min.css";

const Checkout = () => {
  const location = useLocation();
  const { domain } = location.state || {};
  const stripePromise = loadStripe(
    "pk_test_51Mb4EyAHwl7qaxRBbV5iAClDfgwxB9BJIatT14AujEU2WTixFV25Y7vD7To9gAtr12nxDTKunDDej9Y4aQxtSvzI00a8oHEwRw"
  );

  const [paymentMethod, setPaymentMethod] = useState("card");
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    routingNumber: "",
    accountNumber: "",
  });

  const [error, setError] = useState(null); // State to hold error message
  const [paymentDetails, setPaymentDetails] = useState({
    merchantId: "",
    storeId: "",
    transactionReferenceNumber: "",
    transactionAmount: "",
  });
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleBankDetailsChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({ ...bankDetails, [name]: value });
  };

  const handleCardPayment = async () => {
    try {
      setError(null); // Clear previous errors
      const response = await axios.post(
        "https://cwscp.hostin.pk/api/v1/payment/create-checkout-payment",
        {
          domain,
          paymentMethod: "card",
        }
      );

      const sessionId = response.data.id;
      const stripe = await stripePromise;

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error("Error redirecting to checkout:", error);
        }
      } else {
        console.error("Stripe.js has not loaded yet.");
      }
    } catch (error) {
      setError(
        error.response?.data?.error ||
          "An error occurred while processing your request."
      );
    }
  };

  const handleBankTransfer = async (e) => {
    e.preventDefault();
    try {
      setError(null); // Clear previous errors
      const response = await axios.post(
        "https://cwscp.hostin.pk/api/v1/payment/create-checkout-payment",
        {
          domain,
          paymentMethod: "bank",
          bankDetails,
        }
      );

      if (response.data.success) {
        alert("Bank transfer initiated successfully");
      } else {
        setError("Error initiating bank transfer");
      }
    } catch (error) {
      setError(
        error.response?.data?.error ||
          "An error occurred while processing your request."
      );
    }
  };
  const handleAPGPayment = async () => {
    try {
      // Clear previous errors
      setError(null);

      // Example data; replace with actual values or fetch from an API
      const formData = {
        HS_RequestHash: "generated_hash", // Generate this hash using your server
        HS_IsRedirectionRequest: 1,
        HS_ChannelId: "1001",
        HS_ReturnURL: "https://fend.hostin.pk/payment-confirmation",
        HS_MerchantId: paymentDetails.merchantId,
        HS_StoreId: paymentDetails.storeId,
        HS_MerchantHash: "merchant_hash", // Should be generated and secured on your server
        HS_MerchantUsername: "merchant_username", // Secure and retrieve from your server
        HS_MerchantPassword: "merchant_password", // Secure and retrieve from your server
        HS_TransactionReferenceNumber:
          paymentDetails.transactionReferenceNumber,
      };

      // Create a form and submit it to APG
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "https://sandbox.bankalfalah.com/HS/HS/HS";

      for (const key in formData) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = formData[key];
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      setError("An error occurred while processing your request.");
    }
  };

  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div className="w-50">
        <h2>Select Payment Method</h2>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="card"
            name="paymentMethod"
            value="card"
            checked={paymentMethod === "card"}
            onChange={handlePaymentMethodChange}
          />
          <label className="form-check-label" htmlFor="card">
            Pay via Card
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="bank"
            name="paymentMethod"
            value="bank"
            checked={paymentMethod === "bank"}
            onChange={handlePaymentMethodChange}
          />
          <label className="form-check-label" htmlFor="bank">
            Pay via Bank Transfer
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            id="apg"
            name="paymentMethod"
            value="apg"
            checked={paymentMethod === "apg"}
            onChange={handlePaymentMethodChange}
          />
          <label className="form-check-label" htmlFor="apg">
            Pay via Alfa Payment Gateway
          </label>
        </div>

        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
        )}

        {paymentMethod === "card" && (
          <div className="mt-3">
            <button className="btn btn-primary" onClick={handleCardPayment}>
              Proceed to Card Payment
            </button>
          </div>
        )}

        {paymentMethod === "bank" && (
          <div className="mt-3">
            <h2>Enter Bank Details for ACH Transfer</h2>
            <form onSubmit={handleBankTransfer}>
              <div className="mb-3">
                <label htmlFor="accountHolderName" className="form-label">
                  Account Holder Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="accountHolderName"
                  name="accountHolderName"
                  value={bankDetails.accountHolderName}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="routingNumber" className="form-label">
                  Routing Number:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="routingNumber"
                  name="routingNumber"
                  value={bankDetails.routingNumber}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="accountNumber" className="form-label">
                  Account Number:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNumber"
                  name="accountNumber"
                  value={bankDetails.accountNumber}
                  onChange={handleBankDetailsChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Proceed to Bank Transfer
              </button>
            </form>
          </div>
        )}

        {paymentMethod === "apg" && (
          <div className="mt-3">
            <button className="btn btn-primary" onClick={handleAPGPayment}>
              Proceed to Alfa Payment Gateway
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
