import React from 'react';

function Contact() {
  return (
    <div>
      <h2>Contact Page</h2>
      <p>This is the Contact Page.</p>
    </div>
  );
}

export default Contact;
