import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Header from './Header';
import { HomeMax,MailLock } from '@mui/icons-material';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? 0 : `-${drawerWidth}px`,
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft({ toggleDarkMode, open, setOpen }) {
  const theme = useTheme();
  const [openMenu, setOpenMenu] = React.useState({});

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (menu) => {
    setOpenMenu((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const menuStructure = {
    Support: ['Create Support', 'List', 'Closed', 'HelpDesk'],
    Domains: ['Expired/Expiring', 'List', 'Manage DNS'],
    Hosting: ['Expired/Expiring', 'List', 'Manage'],
    Servers: ['Expired/Expiring', 'List', 'Manage'],
    Services: [
      'Social Media Marketing',
      'Email Marketing',
      'Search Engine Marketing',
      'Billboard Marketing',
    ],
    Buy: [
      'Domain',
      'Hosting',
      'VPS',
      'DS',
      'License',
      'Social Media Marketing',
      'Email Marketing',
      'Search Engine Marketing',
      'Billboard Marketing',
      'SMS/WhatsApp Marketing',
    ],
    'My Earnings': ['List', 'Referral Link'],
    'My Orders': ['List', 'Pending', 'Active'],
    Settings: ['Profile', 'Account Security', 'Payment Methods'],
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Header toggleDarkMode={toggleDarkMode} open={open} setOpen={setOpen} />
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {Object.keys(menuStructure).map((mainMenu, index) => (
            <div key={mainMenu}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleMenuClick(mainMenu)}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <HomeMax /> : <MailLock />}
                  </ListItemIcon>
                  <ListItemText primary={mainMenu} />
                  {openMenu[mainMenu] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openMenu[mainMenu]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menuStructure[mainMenu].map((subMenu) => (
                    <ListItem key={subMenu} disablePadding>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText primary={subMenu} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
