import { Accordion, AccordionDetails, AccordionSummary,Button,Table,TableBody,TableCell,TableRow,Typography } from "@mui/material"
import PersistentDrawerLeft from "../components/Drawer"
import { ExpandMore } from "@mui/icons-material"
import styled from "@emotion/styled";
import axios from "axios";
import { useEffect, useState } from "react";

const MuiAccordion = styled((props) => (
    <Accordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    
    '&::before': {
      display: 'none',
    },
  }));
const UserDashboard = ({open, setOpen}) => {
  const [domains,setDomains]= useState([])
  const getOrders = async () => {
      const data = await axios.get('https://cwscp.hostin.pk/api/v1/payment/get-orders-by-user',{
        headers:{
          'Authorization':`Bearer ${localStorage.getItem('token')}`
        }
      })
      console.log(data,"datadataw")
      setDomains(data?.data)
  }

  useEffect(() => {
    getOrders()
  },[])
    return (
        <>
            <PersistentDrawerLeft open={open} setOpen={setOpen} />
            <MuiAccordion sx={{ width:"100%"}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Domains</Typography>
            </AccordionSummary>
            <AccordionDetails>
            

              
              <Table>
                <TableBody>
                  {/* {rows.map((row) => ( */}
                    <TableRow>
                    {domains.length ? domains.map((domain) => (
                      <>
                        <TableCell component="th" scope="row">
                          {domain.domain}
                      </TableCell>
                      <TableCell align="right">
                        <button style={{ border:0, background: "var(--color-button)", color: "var(--color-icon)"}}>Manage</button>
                      </TableCell>
                      </> 
                ) ) : 'No data found!'}
                      
                     
                      {/* <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </AccordionDetails>
          </MuiAccordion>
        </>
    )
}
export default UserDashboard