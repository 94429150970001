// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header";
import MainContent from "./components/MainContent";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute";
import Register from "./pages/Register";
import Login from "./pages/Login";
import "./App.css";
import SharedHosting from "./pages/SharedHosting";
import Checkout from "./pages/Checkout";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import CallBack from "./pages/CallBack";
import Payment from "./pages/Payment";
import UserDashboard from "./pages/UserDashboard";
 

const App = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    // Update body class based on theme
    document.body.className = darkMode ? "dark-mode" : "light-mode";
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
    },
  });

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Header toggleDarkMode={toggleDarkMode} setOpen={setOpen} open={open} />
          <MainContent> 
            <Routes>
              <Route path="/" element={<Home />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/about" element={<About />} />
              </Route>
              <Route path="/contact" element={<Contact />} />
              <Route path="/callback" element={<CallBack />} />
              <Route path="/payment" element={<Payment />} />


              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/success" element={<PaymentConfirmation />} />
              <Route path="/user-dashboard" element={<UserDashboard open={open} setOpen={setOpen}/>} />
              <Route path="/shared-hosting" element={<SharedHosting />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/checkout" element={<Checkout />} />
              </Route>
              
            </Routes>
          </MainContent>
          <Footer />
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default App;
